<template>
  <div class="guide">
    <div class="guide-header flex a-center">
      <div class="title">套版工具</div>
      <div class="info m-l-20">
        还有
        <span style="color: #F56C6C">{{count}}</span>
        个标题没有配置样式
      </div>
      <div class="btns">
        <el-button size="small" @click="saveConfig">保存</el-button>
        <el-button size="small" @click="$router.back()">关闭</el-button>
      </div>
    </div>
    <div class="guide-body flex">
      <div class="chapter content">
        <div class="header flex a-center j-between">
          <div class="title">标题导航</div>
          <div class="btns">
            <!-- <el-button size="mini" type="primary" icon="el-icon-aim">批量选择</el-button> -->
            <el-button size="mini" @click="clearSelect">清空选择</el-button>
            <el-button
              size="mini"
              type="success"
              icon="el-icon-caret-right"
              @click="showBatchDialog"
              :disabled="!selectedChapter.length"
            >批量设置</el-button>
          </div>
        </div>
        <div class="body">
          <!-- <JsonTree
            ref="jsonTree"
            :data="json"
            :isChapterTree="true"
            :showContentLevel="true"
            :expandAll="true"
            :showStrictCheckbox="true"
            @select="handleSelect"
            :selectStyle="confirmApplyConfig"
            :showGuideConfig="true"
            :actLevel="actLevel"
            :getStyleName="getStyleNameByNodeId"
            :getStyle="getStyleByNodeId"
            :titleStyle="titleStyle"
          /> -->
          <!-- :showHighlight="showHighlight"
          :actLevel="actLevel"  -->
        </div>
      </div>
      <div class="config content">
        <div class="header">
          <div class="title">快速设置</div>
        </div>
        <div class="body" @mouseleave="actLevel = 0">
          <div class="config-title">默认配置</div>
          <div
            class="config-item"
            @mouseenter="actLevel = config.identity.content.level"
            v-for="(config, index) in chapterClassConfig"
            :key="index">
            <div>{{config.identity.content.level}}级标题</div>
            <div>{{getStyleNameFromBank(config)}}</div>
            <el-link type="primary" @click="handleApplyConfig(config)">应用</el-link>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="批量配置"
      :visible.sync="batchDialogVisible"
      width="400px"
      @close="selectedStyle = {
        style_name: '未配置',
        style_object: null,
      }"
    >
      <div>
        <div class="m-b-15">已选择{{selectedChapter.length}}个标题，将其样式设置为:</div>
        <el-select value-key="style_object" v-model="selectedStyle" placeholder="请选择" size="small">
          <el-option
            v-for="(item, index) in titleStyle"
            :key="index"
            :label="item.style_name"
            :value="item">
          </el-option>
          <el-option
            label="未配置"
            :value="{
              style_name: '未配置',
              style_object: null,
            }">
          </el-option>
        </el-select>
      </div>
      <div slot="footer">
        <el-button @click="batchDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmApplyConfig()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { iterateNode } from '@/scripts/treeHelper';
import _ from 'lodash';
// import JsonTree from '../JsonTree/index.vue';
import uuid from '@/scripts/uuid';
export default {
  inheritAttrs: false,
  props: ['json', 'styleData'],
  components: {
    // JsonTree
  },
  created () {
    this.init();
  },
  data () {
    return {
      selectedChapter: [],
      flattenJson: null,
      actLevel: 0,
      batchDialogVisible: false,
      selectedStyle: {
        style_name: '未配置',
        style_object: null,
      },
    };
  },
  methods: {
    init () {
      const list = [];
      const json = _.cloneDeep(this.json);
      for (const { node, parent } of iterateNode(json)) {
        if (node.node_type === 'chapter') {
          node.node_parent_id = parent.node_id ? parent.node_id : '';
          // this.riginalDataMap.set(node.node_id, _cloneDeep(node))
          node.parent = parent || '';
          node.path = parent.path ? [...parent.path, parent] : [];
          node.children = node.children || [];
          list.push(node);
        }
      }
      this.flattenJson = list;
    },
    getStyleNameFromBank (actStyle) {
      const { titleStyle } = this;
      const name = titleStyle.find(({ style_object }) => {
        const t = _.cloneDeep(actStyle);
        const n = _.cloneDeep(style_object);
        return _.isEqual({
          config: t.config,
          display: t.display,
          html: t.html,
          html_style_clean: t.html_style_clean,
          style: t.style
        }, {
          config: n.config,
          display: n.display,
          html: n.html,
          html_style_clean: n.html_style_clean,
          style: n.style
        });
      })?.style_name;
      return name || '自定义样式';
    },
    getStyleNameByNodeId (nodeid) {
      const { batchConfigList } = this;
      const targetStyle = batchConfigList.find(config => config.node_id_list.includes(nodeid));
      if (targetStyle) {
        return this.getStyleNameFromBank(targetStyle);
      } else {
        return '未配置';
      }
    },
    getStyleByNodeId (nodeid) {
      const { batchConfigList } = this;
      const targetStyle = batchConfigList.find(config => config.node_id_list.includes(nodeid));
      if (targetStyle) {
        return this.titleStyle.find(({ style_object }) => {
          const t = _.cloneDeep(targetStyle);
          const n = _.cloneDeep(style_object);
          return _.isEqual({
            config: t.config,
            display: t.display,
            html: t.html,
            html_style_clean: t.html_style_clean,
            style: t.style
          }, {
            config: n.config,
            display: n.display,
            html: n.html,
            html_style_clean: n.html_style_clean,
            style: n.style
          });
        }) || {
          style_name: '未设置',
          style_object: null,
        };
      } else {
        return {
          style_name: '未设置',
          style_object: null,
        };
      }
    },
    handleApplyConfig (config) {
      const { batchConfigList, flattenJson } = this;
      const { identity: { content: { level } } } = config;
      const idList = flattenJson.filter(n => n.content.level === level).map(n => n.node_id);
      this.batchConfigList.map(item => {
        this.$set(item, 'node_id_list', item.node_id_list.filter(id => !idList.includes(id)));
      });
      // 查找是否已经有该配置
      const target = batchConfigList.find(item => {
        return _.isEqual({
          config: item.config,
          display: item.display,
          html: item.html,
          html_style_clean: item.html_style_clean,
          style: item.style
        }, {
          config: config.config,
          display: config.display,
          html: config.html,
          html_style_clean: config.html_style_clean,
          style: config.style
        });
      });
      if (target) {
        this.$set(target, 'node_id_list', [...new Set(target.node_id_list.concat(idList))]);
      } else {
        const id = uuid(16);
        this.styleData.components[0].node_batch_cfg_list.push({
          id,
          name: id,
          node_type: 'chapter',
          node_id_list: idList,
          config: config.config,
          display: config.display,
          html: config.html,
          html_style_clean: config.html_style_clean,
          style: config.style
        });
      }
    },
    handleSelect (selectIds) {
      this.selectedChapter = selectIds;
    },
    clearSelect () {
      // console.log(this.$refs.jsonTree.clearSelect);
      this.$refs.jsonTree.clearSelect();
    },
    showBatchDialog () {
      this.batchDialogVisible = true;
    },
    confirmApplyConfig (selectedChapter = this.selectedChapter, selectedStyle = this.selectedStyle) {
      const { batchConfigList } = this;
      batchConfigList.map(item => {
        this.$set(item, 'node_id_list', item.node_id_list.filter(id => !selectedChapter.includes(id)));
      });
      if (selectedStyle.style_object) {
        // 查找是否已经有该配置
        const target = batchConfigList.find(item => {
          return _.isEqual({
            config: item.config,
            display: item.display,
            html: item.html,
            html_style_clean: item.html_style_clean,
            style: item.style
          }, {
            config: selectedStyle.style_object.config,
            display: selectedStyle.style_object.display,
            html: selectedStyle.style_object.html,
            html_style_clean: selectedStyle.style_object.html_style_clean,
            style: selectedStyle.style_object.style
          });
        });
        if (target) {
          this.$set(target, 'node_id_list', [...new Set(target.node_id_list.concat(selectedChapter))]);
        } else {
          const id = uuid(16);
          this.styleData.components[0].node_batch_cfg_list.push({
            id,
            name: id,
            node_type: 'chapter',
            node_id_list: selectedChapter,
            config: selectedStyle.style_object.config,
            display: selectedStyle.style_object.display,
            html: selectedStyle.style_object.html,
            html_style_clean: selectedStyle.style_object.html_style_clean,
            style: selectedStyle.style_object.style
          });
        }
      }
      this.batchDialogVisible = false;
    },
    saveConfig () {
      this.$emit('save');
    },
  },
  computed: {
    levels () {
      return [...new Set(this.flattenJson.map(node => node.content.level))].sort((a, b) => a > b);
    },
    chapterClassConfig () {
      const { styleData } = this;
      return styleData.components[0].class_cfg_list.filter(item => item.identity.node_type === 'chapter');
    },
    titleStyle () {
      const { styleData } = this;
      return styleData.style_bank.title;
    },
    batchConfigList () {
      const { styleData } = this;
      return styleData.components[0].node_batch_cfg_list.filter(item => item.node_type === 'chapter');
    },
    count () {
      const { batchConfigList, flattenJson } = this;
      const ids = flattenJson.map(node => node.node_id);
      const idArr = batchConfigList.reduce((pre, next) => {
        return pre.concat(next.node_id_list);
      }, []).filter(i => ids.includes(i));
      return ids.length - idArr.length;
    }
  }
};
</script>

<style lang="scss" scoped>
  .guide {
    height: calc(100vh - 60px);
    padding: 15px 20px;
    &-header {
      height: 60px;
      border: 1px solid #909399;
      border-bottom: 0px;
      padding: 0 15px;
      .title {
        font-size: 15px;
        font-weight: 700;
      }
      .btns {
        margin-left: auto;
      }
    }
    &-body {
      height: calc(100% - 60px);
      border: 1px solid #909399;
      .chapter {
        width: 65%;
        border-right: 1px solid #909399;
      }
      .config {
        width: 35%;
        &-title {
          width: 80%;
          margin: 15px auto;
          font-weight: 900;
        }
        &-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 15px;
          border: 1px solid #e9e9eb;
          border-bottom: 0px;
          width: 80%;
          margin: 0 auto;
          &:last-child {
            border-bottom: 1px solid #e9e9eb;
          }
        }
      }
      .content {
        .header {
          padding: 0 15px;
          height: 40px;
          line-height: 40px;
          border-bottom: 1px solid #909399;
          background: #e9e9eb;
        }
        .body {
          height: calc(100% - 40px);
          overflow: auto;
        }
      }
    }
  }
</style>
